import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function ContentPolicy() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>CONTENT POLICY</h3>
              <h3>1. AI Generated Designs</h3>
              <p>
                DO NOT attempt to create or share images that are not G-rated or could
                potentially cause harm. Several categories of content are strictly
                prohibited on this platform, including:
              </p>
              <ul>
                <li>
                  Self-harm: including suicide, cutting, eating disorders, and other
                  attempts at harming oneself.
                </li>
                <li>
                  Harassment: including mocking, threatening, or bullying an
                  individual.
                </li>
                <li>
                  Political content: including politicians, ballot-boxes, protests, or
                  other content that may be used to influence the political process or
                  to campaign.
                </li>
                <li>
                  Shocking content: including bodily fluids, obscene gestures, or
                  other profane subjects that may shock or disgust.
                </li>
                <li>
                  Deception: including major conspiracies or events related to major
                  ongoing geopolitical events.
                </li>
                <li>
                  Hate speech: including hateful symbols, negative stereotypes,
                  comparing certain groups to animals/objects, or otherwise expressing
                  or promoting hate based on identity.
                </li>
                <li>Spam: including unsolicited bulk content.</li>
                <li>
                  Violence: including violent acts and the suffering or humiliation of
                  others.
                </li>
                <li>
                  Sexual content: including nudity, sexual acts, sexual services, or
                  content otherwise meant to arouse sexual excitement.
                </li>
                <li>
                  Illegal activity: including drug use, theft, vandalism, and other
                  illegal activities.
                </li>
              </ul>
              <p>
                Public and personal health: including the treatment, prevention,
                diagnosis, or transmission of diseases, or people experiencing health
                ailments.
              </p>

              <h3>2. Copyrighted Works</h3>
              <p>
                Copyright law is a global concept that grants exclusive rights to
                creators of artistic works. The law recognizes that an artist's work
                is automatically protected from the moment it is created and fixed in
                a tangible form, irrespective of the country in which the work was
                created.The protection of an artist's work varies from country to
                country, but it generally includes the exclusive rights to reproduce,
                distribute, display, and create derivative works based on the original
                work. Unauthorized use of an artist's work is considered copyright
                infringement and is illegal in most countries.To use an artist's work
                legally, one must obtain permission through a license or by meeting
                the requirements of fair use or fair dealing, which varies depending
                on the country. Fair use or fair dealing permits limited use of
                copyrighted material without permission for certain purposes, such as
                criticism, commentary, news reporting, teaching, scholarship, or
                research.In conclusion, copyright law exists globally to protect
                artists' work from unauthorized use. Permission to use an artist's
                work must be obtained through a license or by meeting the requirements
                of fair use or fair dealing, which may vary depending on the country
                in question.
              </p>

              <p>
                99GENS LLC has established a “Know Your Artist” (“KYA”) policy which
                means every user who wishes to be able to upload content directly into
                our system must prove they are the copyright holder. Additionally,
                they expressly agree when uploading the content, it's only the content
                we approved during their KYA process.
              </p>

              <p>
                99GENS LLC is committed to protecting the intellectual property rights
                of others and requires all users who wish to upload content directly
                into our system to comply with our “Know Your Artist” (“KYA”) policy.
              </p>

              <h3 id="kya_policy">3. KYA Policy</h3>
              <p>
                The KYA policy requires every user who wishes to upload content
                directly into our system to prove that they are the rightful copyright
                holders of such content.
              </p>
              <h3 className="small-heading">(a) Approval of Content</h3>
              <p>
                When uploading content into our system, users must only upload the
                content that was approved during the KYA process. Any content uploaded
                that was not approved during the KYA process is strictly prohibited.
                99GENS LLC reserves the right to terminate accounts immediately which
                violate these policies.
              </p>
              <h3 className="small-heading">(b) Representations and Warranties</h3>
              <ul>
                <li>
                  By uploading content into our system, users represent and warrant
                  that:
                </li>
                <li>
                  they are the rightful copyright holder of the content they wish to
                  upload;
                </li>
                <li>
                  they have not granted any third party any rights in or to the
                  content that would conflict with the rights granted to 99GENS LLC
                  under this policy;
                </li>
                <li>
                  the content does not infringe any third-party rights, including but
                  not limited to any intellectual property rights or privacy rights;
                  and
                </li>
                <li>
                  the content does not contain any material that is defamatory,
                  obscene, or otherwise unlawful.
                </li>
              </ul>
              <h3 className="small-heading">(a) Indemnification</h3>
              <p>
                Users agree to indemnify and hold 99GENS LLC, its affiliates,
                officers, directors, employees, and agents harmless from and against
                any and all claims, damages, liabilities, costs, and expenses,
                including reasonable attorneys’ fees and expenses, arising out of or
                in connection with any breach of this policy by the user.
              </p>

              <h3 className="small-heading">(b) Violations</h3>
              <p>
                Any user who violates this policy may have their account suspended or
                terminated, and their content removed from our system.
              </p>

              <h3 className="small-heading">(c) Changes to the Policy</h3>
              <p>
                99GENS LLC reserves the right to modify or update this policy at any
                time without prior notice. The most current version of this policy
                will be posted on our website.
              </p>
              <h3 className="small-heading">(f) Contact Us</h3>
              <p>
                If you have any questions or concerns regarding this policy, please
                contact us at:
              </p>
              <p>
                99GENS LLC<br />
                651 N. BROAD ST., SUITE 201<br />
                MIDDLETOWN DE 19709<br />
              </p>
              <p>
                Or, by electronic mail at:
                <a href="mailto:kya@99gens.com"> kya@99gens.com</a>
              </p>
              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
